import React, {Fragment} from "react"
import { useStaticQuery, graphql } from "gatsby"

import Cassette from "../components/cassette"
import Headline from "../components/headline"

const MostViewedArticles = () => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC } ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            hero
          }
        }
      }
    }
  }
  `
)
  const posts = data.allMarkdownRemark.edges;

  const node1 = posts.find(post => post.node.fields.slug === '/2016/06/05/180312/');
  const node2 = posts.find(post => post.node.fields.slug === '/2020/09/20/144916/');
  const node3 = posts.find(post => post.node.fields.slug === '/2016/10/17/235220/');
  const node4 = posts.find(post => post.node.fields.slug === '/2017/02/05/160910/');

  return (
    <Fragment>
      <Headline str="よく読まれている記事"/>
        <Cassette node={node1.node} link={node1.node.fields.slug.substring(1)} key={node1.node.fields.slug} />
        <Cassette node={node2.node} link={node2.node.fields.slug.substring(1)} key={node2.node.fields.slug} />
        <Cassette node={node3.node} link={node3.node.fields.slug.substring(1)} key={node3.node.fields.slug} />
        <Cassette node={node4.node} link={node4.node.fields.slug.substring(1)} key={node4.node.fields.slug} />
    </Fragment>
  )
}

export default MostViewedArticles
