import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RecentArticles from "../components/recent-articles"
import MostViewedArticles from "../components/most-viewed-articles"
import ReadMoreButton from "../components/read-more-button"
import AllTags from "../components/all-tags"
import { BlogIndexQuery } from '../../types/graphql-types'

interface IBlogIndexProps {
  data: BlogIndexQuery;
  location: Location;
}


const BlogIndex: React.FC<IBlogIndexProps> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  return (
    <Layout location={location} title={siteTitle} description={siteDescription}>
      <SEO title = "top" />
      <RecentArticles />
      <ReadMoreButton />
      <MostViewedArticles />
      <ReadMoreButton />
      <AllTags />
      <Bio />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndex {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            hero
          }
        }
      }
    }
  }
`
