import React, {Fragment} from "react"
import { useStaticQuery, graphql } from "gatsby"

import Cassette from "../components/cassette"
import Headline from "../components/headline"

const RecentArticles = () => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            hero
          }
        }
      }
    }
  }
  `
)
const posts = data.allMarkdownRemark.edges

  return (
    <Fragment>
      <Headline str="最新記事"/>
      {posts.map(({ node }) => {
        return(<Cassette node={node} link={node.fields.slug.substring(1)} key={node.fields.slug} />)
      })}
    </Fragment>
  )
}

export default RecentArticles
