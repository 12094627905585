import React, {Fragment} from "react"
import { useStaticQuery, graphql } from "gatsby"

import Tag from "./tag"
import Headline from "./headline"

function AllTags(){
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt(truncate: true)
            fields {
              slug
            }
            html
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              hero
            }
          }
        }
      }
    }
    `)

  const posts = data.allMarkdownRemark.edges;
  let str = "";
  posts.forEach((post) => {
    if (post.node.frontmatter.description){
      str = str + "," + post.node.frontmatter.description
    }
  });

  let tag_arr = str.split(",");
  tag_arr = tag_arr.map((tag) => {return tag.trim()});
  const tags = Array.from(new Set(tag_arr));

  return (
    <Fragment>
      <Headline str={'記事カテゴリ一覧'} />
      {tags.filter(n => n !== "").map((tag) => {return(<Tag tag={tag} />) })}
    </Fragment>
  );
}

export default AllTags
